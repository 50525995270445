<template lang="pug">
.brand-wrapper.brand-still-on-mobile-wrapper.container
  .d-flex.align-items-center
    img.brand-still-on-mobile-logo(:src="require('@/assets/om-logo.svg')")
    a(href="javascript:void(0)" @click="logout").brand-link.ml-auto {{ $t('menu.signOut') }}
  img.brand-still-on-mobile-monk(:src="require('@/assets/admin/img/crying.png')")
  .brand-still-on-mobile-title(v-html="$t('stillOnMobile.title')")
  .brand-still-on-mobile-desc {{ $t('stillOnMobile.desc') }}
  .brand-still-on-mobile-end {{ $t('stillOnMobile.end') }}
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    methods: {
      ...mapActions(['logout']),
    },
  };
</script>

<style lang="sass">
  .brand-still-on-mobile
    font-size: 16px !important
    body
      background: white

    &-wrapper
      padding: 1.25rem !important
      position: relative

    &-logo
      height: 2rem
    &-monk
      margin: 1rem auto
      width: 10rem

    &-title
      font-size: 1.5rem
      margin-bottom: 1rem
      text-align: center
      font-weight: bold

    &-desc
      font-size: 0.9375rem
      color: #878C90
      text-align: center
      margin-bottom: 1rem

    &-end
      font-size: 1rem
      color: #ed5a29
      font-weight: bold
      text-align: center
</style>
